import OptimizelyFxService from './OptimizelyFxService';
import { window } from '../../globals';

const experimentFlag = window?.siteMetadata.experimentFlag;
const experimentVariation = window?.siteMetadata.experimentVariation;

if (experimentFlag && experimentVariation) {
    const { optimizely, user } = OptimizelyFxService.createContext();
    OptimizelyFxService.initDataFile(optimizely).then(() => {
        OptimizelyFxService.activateExperiment(user, experimentFlag, experimentVariation);
    }).catch((reason) => {
        throw new Error(reason as string);
    });
}
