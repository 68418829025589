import type { OptimizelyUserContext } from '@optimizely/optimizely-sdk';
import { window } from '../../globals';

export default class OptimizelyFxAttributes {
    public static pushSiteAttribute(user: OptimizelyUserContext) {
        if (!window || !window.siteMetadata.siteUrl) {
            return;
        }

        user.setAttribute('site', window.siteMetadata.siteUrl);
    }

    public static pushMobileAttribute(user: OptimizelyUserContext) {
        if (!window) {
            return;
        }

        user.setAttribute('isMobile', window.siteMetadata.isMobile);
    }
}
