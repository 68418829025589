import type { Client, OptimizelyUserContext } from '@optimizely/optimizely-sdk';
import { createInstance } from '@optimizely/optimizely-sdk';
import { LogLevel } from '@optimizely/js-sdk-logging';
import Cookie from 'js-cookie';
import { OPTIMIZELY_COOKIE_UUID_NAME } from '../types';
import OptimizelyFxAttributes from './OptimizelyFxAttributes';
import { window } from '../../globals';

export default class OptimizelyFxService {
    public static optimizelyUserContext: OptimizelyUserContext | null = null;

    private static setOptimizelyUserContext(OptimizelyUserContext: OptimizelyUserContext) {
        this.optimizelyUserContext = OptimizelyUserContext;
    }

    public static createContext(): { optimizely: Client; user: OptimizelyUserContext } {
        const optimizely = createInstance({
            logLevel: LogLevel.ERROR,
            sdkKey: window?.optimizelyFxSdk,
        });

        if (!optimizely) {
            throw new Error('OptimizelyFx could not create instance');
        }

        const uuid = Cookie.get(OPTIMIZELY_COOKIE_UUID_NAME);
        if (!uuid) {
            throw new Error('OptimizelyFx Missing uuid');
        }

        const user = optimizely?.createUserContext(uuid);
        if (!user) {
            throw new Error('OptimizelyFx could not createUserContext');
        }

        this.setOptimizelyUserContext(user);
        OptimizelyFxAttributes.pushSiteAttribute(user);
        OptimizelyFxAttributes.pushMobileAttribute(user);

        return {
            optimizely,
            user,
        };
    }

    public static async initDataFile(optimizely: Client) {
        try {
            await optimizely?.onReady().then((result) => {
                if (!result.success) {
                    throw new Error(result.reason);
                }
            });
        } catch (e) {
            throw new Error(e as string);
        }
    }

    public static activateExperiment(user: OptimizelyUserContext, experiment: string, preflightVariation: string) {
        const clientDecision = user.decide(experiment);

        if (clientDecision.enabled) {
            OptimizelyFxService.assertClientDecideIsSameAsPreflight(clientDecision.variationKey, preflightVariation);
        }
    }

    private static assertClientDecideIsSameAsPreflight(clientDecisionVariation: string | null, preflightVariation: string) {
        if (clientDecisionVariation !== preflightVariation) {
            throw new Error('optimizelyFx preflight variation is not same as client');
        }
    }
}
